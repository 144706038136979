import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';


@Injectable()
export class NotificationService {

    constructor() {
    }

    private message(title: string, content: string, icon: string, type: string) {

        window['$'].notify({
            title: '<h4>' + title + '</h4>',
            message: content,
        }, {
            delay: 3000,
            type: type,
            placement: {
                from: 'bottom',
                align: 'right',
            },
            template: '<div data-notify="container" ' +
                '           class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" ' +
                '           role="alert">' +
                '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss"> ' +
                '   <i class="material-icons">close</i>' +
                '</button>' +
                '<i class="material-icons" data-notify="icon">' + icon + '</i> ' +
                '<span data-notify="title">{1}</span> ' +
                '<span data-notify="message">{2}</span>' +
                '<div class="progress" data-notify="progressbar">' +
                '<div class="progress-bar progress-bar-{0}" role="progressbar" ' +
                '   aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" ' +
                '   style="width: 0%;"></div>' +
                '</div>' +
                '<a href="{3}" target="{4}" data-notify="url"></a>' +
                '</div>',
        });
    }


    public Success(title: string, content: string) {
        this.message(title, content, 'check_circle', 'success');
    }

    public Info(title: string, content: string) {
        this.message(title, content, 'notification_important', 'info');
    }

    public Warning(title: string, content: string) {
        this.message(title, content, 'warning', 'warning');
    }

    public Danger(title: string, content: string, ex: any = null) {
        if (!environment.production) {
            console.error('An error occured', {
                title: title,
                message: content,
                exception: ex,
            });
        }
        this.message(title, content, 'error', 'danger');
    }
}
