export const entities = {
    Article: '/articles.json',
    TextEntity: '/texts.json',
    Contact: '/Contact',
    Training: '/trainings.json',
    Experience: '/experiences.json',
    Skillgroup: '/skill-groups.json',
    Skill: '/Skills',
    Company: '/Companies',
    Users: {
        login: '/Users/Authenticate',
        me: '/Users/Me',
        entity: '/Users'
    }
};
